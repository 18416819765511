import { createContext, FC, useContext, useMemo } from 'react';

import { useAgencyConstants, useConfig } from '@core/contexts/ConfigContext';
import { useOptions } from '@core/contexts/OptionsContext';
import { t } from '@core/i18n';
import { FilterPeriodType } from '@shared/components/filter-period';
import { SwitchType } from '@shared/components/switch';
import { AvailableFilters, FiltersTypeConfigs, FilterType } from '@shared/types/common/filter';
import {
  availableLettingTypeOptionsToSelectOptions,
  groupOptionsByBuildingType,
  idArrayToOptions,
  objectToOptions,
  optionsToFlatGroupOptions,
  sourceOptionsSelectOptions,
} from '@shared/utils/options';

export type OptionsItem<T = Id> = { id: T; label: string };

export type UseFieldsResult = {
  [key in AvailableFilters]: FiltersTypeConfigs;
};

export const FieldsContext = createContext<UseFieldsResult>({} as UseFieldsResult);

export const useFields = () => {
  return useContext(FieldsContext);
};

export const FieldsProvider: FC = ({ children }) => {
  // OPTIONS
  const { coogan, user } = useConfig();
  const { constants } = useAgencyConstants();
  const {
    advisoryStatusOptions,
    advisoryTypeOptions,
    availableSizeMetricOptions,
    booleanSpecOptions,
    booleanStringOptions,
    buildStatusOptions,
    companyTagOptions,
    contactTagOptions,
    companyTypeOptions,
    countryOptions,
    currencyOptions,
    devPipelineFundingOptions,
    devPipelineLikelihoodOptions,
    devPipelineStatusOptions,
    devPipelineTypeOptions,
    disposalFittedSpaceOptions,
    disposalGradeOptions,
    disposalPublishedToOptions,
    disposalStatusOptions,
    disposalTagOptions,
    disposalTermsOfEngagementOptions,
    feesAllStagesOptions,
    feesBasisOptions,
    feesStatusOptions,
    feesTypeOptions,
    filterableBulkStatusOptions,
    filterableRentMetricOptions,
    fitoutConceptOptions,
    fitoutConditionOptions,
    includeInstructionOptions,
    investmentCurrencyOptions,
    investmentGradeOptions,
    investmentMarketStatusOptions,
    investmentProfileOptions,
    investorRankingOptions,
    investorRegionOptions,
    investorTypeOptions,
    jointExclusiveInstructionOptions,
    kycCompletedOptions,
    lettingTransactionTagOptions,
    nationalityOptions,
    officesOptions,
    ownershipTypeOptions,
    planningStatusOptions,
    saleTransactionTagOptions,
    saleTypeOptions,
    sectorOptions,
    tagOptions,
    teamOptions,
    tenancyStatusOptions,
    tenureOptions,
  } = useOptions();

  const contactTypesOptions = useMemo(() => {
    return objectToOptions(constants.contactTypes);
  }, [constants]);

  const contactMarketingListOptions = useMemo(() => {
    return idArrayToOptions(constants.marketingLists);
  }, [constants]);

  const floorUnitNameOptions = useMemo(() => objectToOptions(constants.floorUnitNameOptions), [constants]);

  const leaseTypeOptions = useMemo(() => {
    return availableLettingTypeOptionsToSelectOptions(constants.availableLettingTypeOptions);
  }, [constants]);

  const societyDisposalLeaseTypeOptions = useMemo(() => {
    return objectToOptions(constants.leaseTypes);
  }, [constants]);

  const propertyTypeOptions = useMemo(() => groupOptionsByBuildingType(constants, user), [constants, user]);

  const sourceOptions = useMemo(() => sourceOptionsSelectOptions(constants.localisation?.sourceOptions), [constants]);

  const transactionStatusOptions = useMemo(() => {
    return objectToOptions(constants.transactionStatusOptions);
  }, [constants]);

  const result: UseFieldsResult = {
    [AvailableFilters.advisoryStatus]: {
      type: FilterType.multiSelect,
      label: t('status'),
      options: advisoryStatusOptions,
    },

    [AvailableFilters.advisoryType]: {
      type: FilterType.multiSelect,
      label: t('type'),
      options: advisoryTypeOptions,
    },

    [AvailableFilters.agency]: {
      type: FilterType.lookupAgency,
      label: t('agency'),
      placeholder: t('xtextx_ellipsis', { text: t('search_agency') }),
    },

    [AvailableFilters.agent]: {
      type: FilterType.lookupAgent,
      label: t('agent'),
      placeholder: t('xtextx_ellipsis', { text: t('search_agent') }),
    },

    [AvailableFilters.amlOrKycCompleted]: {
      type: FilterType.singleSelect,
      label: t('aml_or_kyc_completed'),
      options: useMemo(() => [{ label: t('select'), id: '' }, ...booleanStringOptions], [booleanStringOptions]),
    },

    [AvailableFilters.assignee]: {
      type: FilterType.lookupAgent,
      label: t('assignee'),
      placeholder: t('xtextx_ellipsis', { text: t('search_assignee') }),
    },

    [AvailableFilters.availableSpace]: {
      type: FilterType.range,
      label: t('available_space'),
      defaultMetric: constants.defaultSizeMetric,
      metric: true,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.buildingGrade]: {
      type: FilterType.multiSelect,
      label: t('property_grade'),
      options: disposalGradeOptions,
    },

    [AvailableFilters.buildingTypes]: {
      type: FilterType.multiGroupSelect,
      label: t('property_types'),
      groupOptions: propertyTypeOptions,
    },

    [AvailableFilters.buildStatus]: {
      type: FilterType.multiSelect,
      label: t('build_status'),
      options: buildStatusOptions,
    },

    [AvailableFilters.builtYear]: {
      type: FilterType.range,
      label: t('year_built'),
    },

    [AvailableFilters.bulkJobStatus]: {
      type: FilterType.multiSelect,
      label: t('bulk_job_status'),
      options: filterableBulkStatusOptions,
    },

    [AvailableFilters.capVal]: {
      type: FilterType.range,
      label: t('capital_value_bracket_per_sqft_bracket'),
      currency: true,
      defaultCurrency: constants.localisation.currencyDefaultIso ?? '',
      currencyOptions: currencyOptions,
    },

    [AvailableFilters.country]: {
      type: FilterType.multiGroupSelect,
      label: t('country'),
      groupOptions: optionsToFlatGroupOptions(countryOptions),
      placeholder: t('select_a_country'),
    },

    [AvailableFilters.company]: {
      type: FilterType.lookupCompany,
      label: t('company'),
      placeholder: t('xtextx_ellipsis', { text: t('search_companies') }),
    },

    [AvailableFilters.companySource]: {
      type: FilterType.multiSelect,
      label: t('source_of_company'),
      placeholder: t('select'),
      options: sourceOptions,
    },

    [AvailableFilters.companyTags]: {
      type: FilterType.multiSelect,
      label: t('tags'),
      options: companyTagOptions,
    },

    [AvailableFilters.companyType]: {
      type: FilterType.multiSelect,
      label: t('type'),
      options: companyTypeOptions,
    },

    [AvailableFilters.completionDate]: {
      type: FilterType.dateRange,
      label: t('completion_date'),
    },

    [AvailableFilters.contact]: {
      type: FilterType.lookupContact,
      label: t('contact'),
      placeholder: t('xtextx_ellipsis', { text: t('search_contacts') }),
    },

    [AvailableFilters.contactTags]: {
      type: FilterType.multiSelect,
      label: t('tags'),
      options: contactTagOptions,
    },

    [AvailableFilters.contactType]: {
      type: FilterType.multiSelect,
      label: t('type'),
      options: contactTypesOptions,
    },

    [AvailableFilters.constructionStartYear]: {
      type: FilterType.range,
      label: t('construction_start_year'),
    },

    [AvailableFilters.created]: {
      type: FilterType.date,
      label: t('date_added_1'),
    },

    [AvailableFilters.createdCrmCompanies]: {
      type: FilterType.date,
      label: t('date_added_1'),
      supportedTypes: [FilterPeriodType.range, FilterPeriodType.daysAgo],
    },

    [AvailableFilters.createdCrmContacts]: {
      type: FilterType.date,
      label: t('date_added_1'),
      supportedTypes: [FilterPeriodType.range, FilterPeriodType.daysAgo],
    },

    [AvailableFilters.createdDateRange]: {
      type: FilterType.dateRange,
      label: t('date_added'),
    },

    [AvailableFilters.createdTransactionsSales]: {
      type: FilterType.date,
      label: t('date_added_1'),
      supportedTypes: [FilterPeriodType.range, FilterPeriodType.daysAgo],
    },

    [AvailableFilters.dateAdded]: {
      type: FilterType.date,
      label: t('date_added_1'),
      supportedTypes: [FilterPeriodType.range, FilterPeriodType.daysAgo],
    },

    [AvailableFilters.disposalDevelopmentCompletionYear]: {
      type: FilterType.range,
      label: t('pc_year'),
    },

    [AvailableFilters.disposalDevelopmentFloorSize]: {
      type: FilterType.range,
      label: t('floor_size_1'),
      defaultMetric: constants.defaultSizeMetric,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.disposalDevelopmentFunding]: {
      type: FilterType.multiSelect,
      label: t('funding'),
      options: devPipelineFundingOptions,
    },

    [AvailableFilters.disposalDevelopmentLikelihood]: {
      type: FilterType.multiSelect,
      label: t('development_likelihood'),
      options: devPipelineLikelihoodOptions,
    },

    [AvailableFilters.disposalDevelopmentNoOfFloors]: {
      type: FilterType.range,
      label: t('no_of_floors_1'),
    },

    [AvailableFilters.disposalDevelopmentPreLetCommitted]: {
      type: FilterType.range,
      label: t('pre_hyphen_let_or_committed_size'),
      defaultMetric: constants.defaultSizeMetric,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.disposalDevelopmentSpeculative]: {
      type: FilterType.range,
      label: t('speculative_size'),
      defaultMetric: constants.defaultSizeMetric,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.disposalDevelopmentStatus]: {
      type: FilterType.multiSelect,
      label: t('development_status_1'),
      options: devPipelineStatusOptions,
    },

    [AvailableFilters.disposalDevelopmentTotalAreaProposed]: {
      type: FilterType.range,
      label: t('total_area_proposed_size'),
      defaultMetric: constants.defaultSizeMetric,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.disposalDevelopmentType]: {
      type: FilterType.multiSelect,
      label: t('development_type'),
      options: devPipelineTypeOptions,
    },

    [AvailableFilters.disposalFittedSpaces]: {
      type: FilterType.multiSelect,
      label: t('fitted_space_1'),
      options: disposalFittedSpaceOptions,
    },

    [AvailableFilters.disposalHasPresentation]: {
      type: FilterType.singleSelect,
      label: t('interest_schedule_presentations'),
      options: useMemo(
        () => [
          { label: t('select'), id: '' },
          { label: t('has_presentation'), id: '1' },
          { label: t('no_presentation'), id: '0' },
        ],
        []
      ),
    },

    [AvailableFilters.disposalPublishedTo]: {
      type: FilterType.multiSelect,
      label: t('published_to'),
      options: disposalPublishedToOptions,
    },

    [AvailableFilters.disposalStatus]: {
      type: FilterType.multiSelect,
      removable: false,
      label: t('status'),
      options: disposalStatusOptions,
    },

    [AvailableFilters.disposalTags]: {
      type: FilterType.multiSelect,
      label: t('tags'),
      options: disposalTagOptions,
    },

    [AvailableFilters.disposalTermsOfEngagement]: {
      type: FilterType.multiSelect,
      label: t('have_terms_of_engagement_been_agreed_question'),
      options: disposalTermsOfEngagementOptions,
    },

    [AvailableFilters.dueDate]: {
      type: FilterType.dateRange,
      label: t('due_date'),
    },

    [AvailableFilters.feeBasis]: {
      type: FilterType.multiSelect,
      label: t('fee_basis'),
      options: feesBasisOptions,
    },

    [AvailableFilters.feesStage]: {
      type: FilterType.multiSelect,
      label: t('stage'),
      options: feesAllStagesOptions,
    },

    [AvailableFilters.feesStatus]: {
      type: FilterType.multiSelect,
      label: t('status'),
      options: feesStatusOptions,
    },

    [AvailableFilters.feesType]: {
      type: FilterType.multiSelect,
      label: t('type'),
      options: feesTypeOptions,
    },

    [AvailableFilters.externalOnly]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('include_my_disposals'),
    },

    [AvailableFilters.fitoutConcept]: {
      type: FilterType.multiSelect,
      label: t('fitout_concept'),
      options: fitoutConceptOptions,
    },

    [AvailableFilters.fitoutCondition]: {
      type: FilterType.multiSelect,
      label: t('fitout_condition'),
      options: fitoutConditionOptions,
    },

    [AvailableFilters.floorsAboveGround]: {
      type: FilterType.range,
      label: t('floors_above_ground'),
    },

    [AvailableFilters.floorsBelowGround]: {
      type: FilterType.range,
      label: t('floors_below_ground'),
    },

    [AvailableFilters.floorUnitOptions]: {
      type: FilterType.multiSelect,
      label: t('floors_or_units'),
      options: floorUnitNameOptions,
    },

    [AvailableFilters.has_active_disposals]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('has_active_disposals'),
    },

    [AvailableFilters.has_active_leases]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('has_active_leases'),
    },

    [AvailableFilters.has_active_requirements]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('has_active_requirements'),
    },

    [AvailableFilters.has_active_sales]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('has_active_sales'),
    },

    [AvailableFilters.has_active_viewings]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('has_active_viewings'),
    },

    [AvailableFilters.has_investor_profile]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('has_investor_profile'),
    },

    [AvailableFilters.hasAvailability]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('has_availability'),
    },

    [AvailableFilters.hasLeases]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('has_leases'),
    },

    [AvailableFilters.hasOpenTasks]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('has_open_tasks'),
    },

    [AvailableFilters.hasSales]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('has_sales'),
    },

    [AvailableFilters.inResearchStock]: {
      type: FilterType.singleSelect,
      label: t('in_research_stock_question'),
      options: booleanStringOptions,
    },

    [AvailableFilters.independentBuilding]: {
      type: FilterType.singleSelect,
      label: t('independent_building'),
      options: booleanSpecOptions,
    },

    [AvailableFilters.investmentGrade]: {
      type: FilterType.multiSelect,
      label: t('investment_grade'),
      options: useMemo(
        () => [...investmentGradeOptions, { id: 'no_preference', label: t('no_preference') }],
        [investmentGradeOptions]
      ),
    },

    [AvailableFilters.investmentMarketStatus]: {
      type: FilterType.multiSelect,
      label: t('market_status'),
      options: investmentMarketStatusOptions,
    },

    [AvailableFilters.investorAmountUnderManagement]: {
      type: FilterType.range,
      label: t('aum'),
      currency: true,
      defaultCurrency: constants.localisation.currencyDefaultIso ?? '',
      currencyOptions: investmentCurrencyOptions,
    },

    [AvailableFilters.investorNationality]: {
      type: FilterType.multiSelect,
      label: t('nationality'),
      options: countryOptions,
    },

    [AvailableFilters.investorRanking]: {
      type: FilterType.multiSelect,
      label: t('investor_ranking'),
      options: investorRankingOptions,
    },

    [AvailableFilters.investorRegion]: {
      type: FilterType.multiSelect,
      label: t('investor_region'),
      options: investorRegionOptions,
    },

    [AvailableFilters.investorType]: {
      type: FilterType.multiSelect,
      label: t('investor_type'),
      options: investorTypeOptions,
    },

    [AvailableFilters.invoice]: {
      type: FilterType.text,
      label: t('invoice_no'),
      placeholder: t('enter_invoice_no'),
    },

    [AvailableFilters.jointExclusiveInstructions]: {
      type: FilterType.singleSelect,
      label: t('joint_or_exclusive_instruction'),
      options: jointExclusiveInstructionOptions,
    },

    [AvailableFilters.kycCompleted]: {
      type: FilterType.multiSelect,
      label: t('kyc_checks_undertaken_question'),
      options: kycCompletedOptions,
      visible: !!user.organisation?.settings?.kyc_enabled,
    },

    [AvailableFilters.leadAgent]: {
      type: FilterType.lookupUser,
      label: t('lead_agent'),
      placeholder: t('xtextx_ellipsis', { text: t('search_agents') }),
    },

    [AvailableFilters.leaseBreak]: {
      type: FilterType.date,
      label: t('lease_break'),
    },

    [AvailableFilters.leaseEnd]: {
      type: FilterType.date,
      label: t('lease_end_date'),
    },

    [AvailableFilters.leaseExpiry]: {
      type: FilterType.date,
      label: t('lease_expiry'),
    },

    [AvailableFilters.leaseReminder]: {
      type: FilterType.dateRange,
      label: t('lease_end_reminder_dates'),
    },

    [AvailableFilters.leaseStart]: {
      type: FilterType.date,
      label: t('lease_start_date'),
    },

    [AvailableFilters.leaseTypes]: {
      type: FilterType.multiSelect,
      label: t('lease_type_1'),
      options: leaseTypeOptions,
    },

    [AvailableFilters.lettingTransactionTags]: {
      type: FilterType.multiSelect,
      label: t('tags'),
      options: lettingTransactionTagOptions,
    },

    [AvailableFilters.location]: {
      type: FilterType.text,
      label: t('location'),
      placeholder: t('enter_location'),
    },

    [AvailableFilters.locationRadios]: {
      type: FilterType.locationRadios,
      label: t('locations_area'),
    },

    [AvailableFilters.lotSize]: {
      type: FilterType.range,
      label: t('lot_size'),
      currency: true,
      defaultCurrency: constants.localisation.currencyDefaultIso ?? '',
      currencyOptions: currencyOptions,
    },

    [AvailableFilters.marketing_lists]: {
      type: FilterType.multiSelect,
      label: t('marketing_lists'),
      options: contactMarketingListOptions,
    },

    [AvailableFilters.marketing_permission]: {
      type: FilterType.singleSelect,
      label: t('property_alerts'),
      options: useMemo(() => [{ label: t('select'), id: '' }, ...booleanStringOptions], [booleanStringOptions]),
    },

    [AvailableFilters.nationality]: {
      type: FilterType.multiGroupSelect,
      label: t('nationality'),
      groupOptions: optionsToFlatGroupOptions(nationalityOptions),
    },

    [AvailableFilters.newMatches]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('only_show_new_match_requests'),
    },

    [AvailableFilters.niy]: {
      type: FilterType.range,
      label: t('net_initial_yield'),
    },

    [AvailableFilters.occupiedSpace]: {
      type: FilterType.range,
      label: t('occupied_space'),
      defaultMetric: constants.defaultSizeMetric,
      metric: true,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.office]: {
      type: FilterType.multiSelect,
      label: t('office'),
      options: officesOptions,
    },

    [AvailableFilters.ourInstructions]: {
      type: FilterType.multiSelect,
      label: t('our_instructions_question'),
      options: includeInstructionOptions,
    },

    [AvailableFilters.ownershipTypes]: {
      type: FilterType.multiSelect,
      label: t('sale_tenure'),
      options: ownershipTypeOptions,
    },

    [AvailableFilters.parkingSpacesAboveGround]: {
      type: FilterType.range,
      label: t('parking_spaces_above_ground'),
    },

    [AvailableFilters.parkingSpacesBelowGround]: {
      type: FilterType.range,
      label: t('parking_spaces_below_ground'),
    },

    [AvailableFilters.pendingMatches]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('only_show_pending_match_requests'),
    },

    [AvailableFilters.planningStatus]: {
      type: FilterType.multiSelect,
      label: t('planning_status'),
      options: planningStatusOptions,
    },

    [AvailableFilters.postcode]: {
      type: FilterType.text,
      label: t('postcode'),
      placeholder: t('enter_postcode'),
    },

    [AvailableFilters.probabilityRange]: {
      type: FilterType.range,
      label: t('probability'),
    },

    [AvailableFilters.propertyAddOnFactor]: {
      type: FilterType.range,
      label: t('property_add_hyphen_on_factor'),
    },

    [AvailableFilters.propertySize]: {
      type: FilterType.range,
      label: t('total_property_size'),
      defaultMetric: constants.defaultSizeMetric,
      metric: true,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.publishedToRadius]: {
      type: FilterType.multiSelect,
      label: t('published_to_radius'),
      options: useMemo(
        () => [
          { label: t('published'), id: 'published' },
          { label: t('not_published'), id: 'not_published' },
          { label: t('valid'), id: 'valid' },
          { label: t('not_valid'), id: 'not_valid' },
        ],
        []
      ),
    },

    [AvailableFilters.recordOwner]: {
      type: FilterType.lookupUser,
      label: t('record_owner'),
      placeholder: t('xtextx_ellipsis', { text: t('record_owner') }),
    },

    [AvailableFilters.refurbYear]: {
      type: FilterType.range,
      label: t('last_modernisation_year'),
    },

    [AvailableFilters.rentCurrency]: {
      type: FilterType.range,
      label: t('rent_range'),
      defaultMetric: constants.defaultRentMetric,
      metricOptions: filterableRentMetricOptions,
      currency: true,
      defaultCurrency: constants.localisation.currencyDefaultIso ?? '',
      currencyOptions: currencyOptions,
    },

    [AvailableFilters.rentReview]: {
      type: FilterType.date,
      label: t('rent_review'),
    },

    [AvailableFilters.reverseSegmentIntersectLogic]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('reverse_location_filter_bracket_impersonate_only_bracket'),
      condition: coogan,
    },

    [AvailableFilters.riskProfile]: {
      type: FilterType.multiSelect,
      label: t('risk_profile'),
      options: investmentProfileOptions,
    },

    [AvailableFilters.salePrice]: {
      type: FilterType.range,
      label: t('sale_price'),
      currency: true,
      defaultCurrency: constants.localisation.currencyDefaultIso ?? '',
      currencyOptions: currencyOptions,
    },

    [AvailableFilters.saleTransactionTags]: {
      type: FilterType.multiSelect,
      label: t('tags'),
      options: saleTransactionTagOptions,
    },

    [AvailableFilters.saleType]: {
      type: FilterType.singleSelect,
      label: t('sale_type'),
      options: saleTypeOptions,
    },

    [AvailableFilters.sectors]: {
      type: FilterType.multiSelect,
      label: t('sectors'),
      options: sectorOptions,
    },

    [AvailableFilters.shareWithWip]: {
      type: FilterType.multiSelect,
      label: t('share_with_wip'),
      options: booleanStringOptions,
    },

    [AvailableFilters.showSingleFloors]: {
      type: FilterType.switch,
      switchType: SwitchType.square,
      label: t('single_floorunit_only'),
    },

    [AvailableFilters.size]: {
      type: FilterType.range,
      label: t('size_range'),
      defaultMetric: constants.defaultSizeMetric,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.societyDisposalLeaseTypes]: {
      type: FilterType.multiSelect,
      label: t('lease_type_1'),
      options: societyDisposalLeaseTypeOptions,
    },

    [AvailableFilters.sources]: {
      type: FilterType.multiSelect,
      label: t('source'),
      options: sourceOptions,
    },

    [AvailableFilters.startDate]: {
      type: FilterType.dateRange,
      label: t('start_date'),
    },

    [AvailableFilters.tags]: {
      type: FilterType.multiSelect,
      label: t('tags'),
      options: tagOptions,
    },

    [AvailableFilters.teams]: {
      type: FilterType.multiSelect,
      label: t('teams'),
      options: teamOptions,
    },

    [AvailableFilters.tenancyStatus]: {
      type: FilterType.multiSelect,
      label: t('tenancy_status_1'),
      options: tenancyStatusOptions,
    },

    [AvailableFilters.tenure]: {
      type: FilterType.multiSelect,
      label: t('tenure'),
      options: tenureOptions,
    },

    [AvailableFilters.tny]: {
      type: FilterType.range,
      label: t('target_net_yield'),
    },

    [AvailableFilters.transactionDate]: {
      type: FilterType.date,
      label: t('transaction_date'),
    },

    [AvailableFilters.transactionStatus]: {
      type: FilterType.multiSelect,
      label: t('status'),
      options: transactionStatusOptions,
    },

    [AvailableFilters.typicalFloorSize]: {
      type: FilterType.range,
      label: t('typical_floor_size'),
      defaultMetric: constants.defaultSizeMetric,
      metric: true,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.updated]: {
      type: FilterType.date,
      label: t('date_updated'),
      supportedTypes: [FilterPeriodType.range, FilterPeriodType.daysAgo],
    },

    [AvailableFilters.user]: {
      type: FilterType.lookupUser,
      label: t('user'),
      placeholder: t('xtextx_ellipsis', { text: t('search_users') }),
    },

    [AvailableFilters.vacantSpace]: {
      type: FilterType.range,
      label: t('vacant_space'),
      defaultMetric: constants.defaultSizeMetric,
      metric: true,
      metricOptions: availableSizeMetricOptions,
    },

    [AvailableFilters.waultToExpiry]: {
      type: FilterType.range,
      label: t('wault_to_expiry'),
    },
  };

  return <FieldsContext.Provider value={result}>{children}</FieldsContext.Provider>;
};
