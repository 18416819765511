import Echo, { Channel } from 'laravel-echo';
import { noop } from 'lodash';
import Pusher from 'pusher-js';
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { getBaseUrl } from '@core/base-url';
import { useAgencyConstants, useConfig } from '@core/contexts/ConfigContext';
import { useStorageListeners } from '@core/hooks/localStorage';
import {
  EchoListenKey,
  PusherEventKey,
  PusherEventPayload,
  PusherNotificationKey,
  PusherNotificationPayload,
} from '@shared/types/common/events';

import {
  addOrUpdateToast,
  echoAuthorizer,
  getEventToast,
  getNotificationToast,
  openToastLink,
} from './PusherContext.utils';

// Expose Pusher on window, so Echo can access it
window.Pusher = Pusher;

type DismissedToastsValue = string[] | null;

const DISMISSED_TOASTS_KEY = 'dismissedToasts';

abstract class CustomChannel extends Channel {
  abstract listen<TK extends PusherEventKey>(
    event: EchoListenKey<TK>,
    callback: (payload: PusherEventPayload[TK]) => any
  ): Channel;
  abstract stopListening<TK extends PusherEventKey>(event: EchoListenKey<TK>, callback?: () => any): Channel;
  abstract notification<TK extends PusherNotificationKey>(
    callback: (payload: PusherNotificationPayload[TK]) => any
  ): Channel;
}

export interface ToastItem {
  content: ReactNode;
  key: string;
  replaceKey?: string;
  time: number;
}

export interface PusherContextValue {
  addEventToast: (event: PusherEventKey, suppliedPayload: ValueOf<PusherEventPayload>) => void;
  addNotificationToast: (event: PusherNotificationKey, suppliedPayload: ValueOf<PusherNotificationPayload>) => void;
  echo: Echo | undefined;
  toasts: ToastItem[];
}

export const PusherContext = createContext<PusherContextValue>({
  addEventToast: noop,
  addNotificationToast: noop,
  echo: undefined,
  toasts: [],
});

export const usePusher = () => {
  return useContext(PusherContext);
};

export const PusherProvider: FC = ({ children }) => {
  const { pusherKey, user } = useConfig();
  const { constants } = useAgencyConstants();
  const storageListeners = useStorageListeners();

  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const dismissToast = (toastKey: string) => {
    // console.log('dismissToast:', toastKey);
    storageListeners.set<DismissedToastsValue>(DISMISSED_TOASTS_KEY, (prev) => {
      if (prev) {
        return [...prev, toastKey];
      } else {
        return [toastKey];
      }
    });
  };

  const removeToast = (toastKey: string) => {
    // console.log('removeToast:', toastKey);
    dismissToast(toastKey);
    setToasts((_prev) => _prev.filter((toast) => toast.key !== toastKey));
  };

  const echoInstance = useMemo(
    () =>
      new Echo({
        broadcaster: 'pusher',
        cluster: 'eu',
        encrypted: true,
        key: pusherKey,
        wsHost: getBaseUrl(),
        authorizer: echoAuthorizer,
      }),
    [pusherKey]
  );

  const isDismissed = (toast: ToastItem) => {
    return storageListeners.get<DismissedToastsValue>(DISMISSED_TOASTS_KEY)?.includes(toast.key);
  };

  const addEventToast = useCallback(
    (event: PusherEventKey, suppliedPayload: ValueOf<PusherEventPayload>) => {
      // console.log(`Pusher event: ${event}`, suppliedPayload);
      const toast = getEventToast(event, suppliedPayload, user, removeToast, openToastLink);

      if (toast && !isDismissed(toast)) {
        // console.log(`addEventToast. adding: `, event, suppliedPayload);
        setToasts((_prev) => addOrUpdateToast(toast, _prev));
      }
    },
    [user]
  );

  const addNotificationToast = useCallback(
    (event: PusherNotificationKey, suppliedPayload: ValueOf<PusherNotificationPayload>) => {
      const toast = getNotificationToast(event, suppliedPayload, removeToast, openToastLink);

      if (toast && !isDismissed(toast)) {
        // console.log(`addNotificationToast. adding: `, event, suppliedPayload);
        setToasts((_prev) => addOrUpdateToast(toast, _prev));
      }
    },
    [user]
  );

  useEffect(() => {
    // User event listeners
    const userChannel: CustomChannel = echoInstance.private(`${constants.APP_ENV}-App.User.${user.id}`);
    userChannel.error((error: any) => console.error('User channel error:', error));
    userChannel.listen(`.${PusherEventKey.SocietyMatchMessageAdded}`, (data) => {
      addEventToast(PusherEventKey.SocietyMatchMessageAdded, data);
    });

    // Society event listeners
    const societyChannels: CustomChannel[] = user.societies.map((society) => {
      return echoInstance.private('society-' + society.id + '-pulse');
    });
    societyChannels.forEach((channel, index) => {
      channel.error((error: any) => console.error(`Society ${user.societies[index]?.id} channel error:`, error));

      if (user.notify_settings.acquisitions.where.desktop) {
        channel.listen(`.${PusherEventKey.AcquisitionPublishedToSociety}`, (data) => {
          addEventToast(PusherEventKey.AcquisitionPublishedToSociety, data);
        });
      }

      if (user.notify_settings.disposals.where.desktop) {
        channel.listen(`.${PusherEventKey.LettingPublishedToSociety}`, (data) => {
          addEventToast(PusherEventKey.LettingPublishedToSociety, data);
        });
      }
    });

    // Private user event listeners
    const privateUserChannel: CustomChannel = echoInstance.private(`App.User.${user.id}`);
    privateUserChannel.error((error: any) => console.error('Private channel error:', error));
    privateUserChannel.notification((data) => addNotificationToast(data.type, data));
    privateUserChannel.listen(`.${PusherEventKey.BulkActionProgress}`, (data) => {
      addEventToast(PusherEventKey.BulkActionProgress, data);
    });
    privateUserChannel.listen(`.${PusherEventKey.BulkActionCompleted}`, (data) => {
      addEventToast(PusherEventKey.BulkActionCompleted, data);
    });

    return () => {
      // Clear user event listeners
      privateUserChannel.stopListening(`.${PusherEventKey.BulkActionProgress}`);
      privateUserChannel.stopListening(`.${PusherEventKey.BulkActionCompleted}`);
      userChannel.stopListening(`.${PusherEventKey.SocietyMatchMessageAdded}`);

      // Clear society event listeners
      societyChannels.forEach((channel) => {
        if (user.notify_settings.acquisitions.where.desktop) {
          channel.stopListening(`.${PusherEventKey.AcquisitionPublishedToSociety}`);
        }
        if (user.notify_settings.disposals.where.desktop) {
          channel.stopListening(`.${PusherEventKey.LettingPublishedToSociety}`);
        }
      });
    };
  }, [constants, echoInstance, user]);

  // Ensure the toasts are cleared when the user dismisses a toast in another tab
  useEffect(() => {
    storageListeners.listen<DismissedToastsValue>(DISMISSED_TOASTS_KEY, (dismissedToasts) => {
      // console.log('dismissedToasts:', dismissedToasts);
      if (dismissedToasts) {
        setToasts((prev) => prev.filter((toast) => !dismissedToasts.includes(toast.key)));
      }
    });
  }, []);

  // Ensure the toasts are automatically dismissed
  useEffect(() => {
    const interval = setInterval(() => {
      setToasts((prev) => {
        const newToasts = prev.filter((toast) => {
          if (Date.now() - toast.time > 10000) {
            dismissToast(toast.key);
            return false;
          }
          return true;
        });

        return newToasts.length !== prev.length ? newToasts : prev;
      });
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const contextValue = useMemo(() => {
    const result: PusherContextValue = {
      addEventToast: addEventToast,
      addNotificationToast: addNotificationToast,
      echo: echoInstance,
      toasts: toasts,
    };
    return result;
  }, [addEventToast, addNotificationToast, echoInstance, toasts]);

  return <PusherContext.Provider value={contextValue}>{children}</PusherContext.Provider>;
};
